const routeProps = (route) => ({ ...route.query, ...route.params, installed: (String(route.query.installed).toLowerCase() === 'true') });

export const pluginRoutes = [
  {
    path: "/plugins",
    name: "Plugins",
    meta: { protected: true, account_needed: true },
    props: true,
    component: () => import("@/views/Plugins/Plugins"),
  },
  {
    path: "/plugins/install/smarty/:id?",
    name: "AddPluginSmarty",
    meta: { protected: true, account_needed: true },
    props: routeProps,
    component: () => import("@/views/Plugins/AddPluginSmarty"),
  },
  {
    path: "/plugins/install/squint_metrics/:id?",
    name: "AddPluginSquintMetrics",
    meta: { protected: true, account_needed: true },
    props: routeProps,
    component: () => import("@/views/Plugins/AddPluginSquintMetrics"),
  },
  {
    path: "/plugins/install/click_send/:id?",
    name: "AddPluginClickSend",
    meta: { protected: true, account_needed: true },
    props: routeProps,
    component: () => import("@/views/Plugins/AddPluginClicksend"),
  },
  {
    path: "/plugins/install/automated_export/:id?",
    name: "AddPluginAutomatedExport",
    meta: { protected: true, account_needed: true },
    props: routeProps,
    component: () => import("@/views/Plugins/AddPluginAutomatedExport"),
  },
  {
    path: "/plugins/install/automated_import/:id?",
    name: "AddPluginAutomatedImport",
    meta: { protected: true, account_needed: true },
    props: routeProps,
    component: () => import("@/views/Plugins/AddPluginAutomatedImport"),
  },
  {
    path: "/plugins/install/google_analytics/:id?",
    name: "AddPluginGoogleAnalytics",
    meta: { protected: true, account_needed: true },
    props: routeProps,
    component: () => import("@/views/Plugins/AddPluginGoogleAnalytics"),
  },
  {
    path: "/plugins/install/demo/:id?",
    name: "AddPluginDemo",
    meta: { protected: true, account_needed: true },
    props: routeProps,
    component: () => import("@/views/Plugins/AddPluginDemo"),
  },
  {
    path: "/google/analytics",
    name: "GoogleAnalytics",
    meta: { protected: true, account_needed: true },
    props: true,
    component: () => import("@/views/Plugins/Google/GoogleAnalyticsView"),
  },
  {
    path: "/plugins/install/ubc_alcon/:id?",
    name: "AddPluginUBCAlcon",
    meta: { protected: true, account_needed: true },
    props: routeProps,
    component: () => import("@/views/Plugins/AddPluginUBCAlcon"),
  },
  {
    path: "/ubc_alcon/import",
    name: "PluginUbcAlconImport",
    meta: { protected: true, account_needed: true },
    component: () => import("@/views/Plugins/UbcAlcon/UbcAlconImportsView"),
  },
  {
    path: "/ubc_alcon/export",
    name: "PluginUbcAlconExport",
    meta: { protected: true, account_needed: true },
    component: () => import("@/views/Plugins/UbcAlcon/UbcAlconExportsView"),
  },
  {
    path: "/plugins/install/arrowhead/:id?",
    name: "AddPluginArrowhead",
    meta: { protected: true, account_needed: true },
    props: routeProps,
    component: () => import("@/views/Plugins/AddPluginArrowhead"),
  },
  {
    path: "/arrowhead/import",
    name: "PluginArrowheadImport",
    meta: { protected: true, account_needed: true },
    props: {component_name: 'AddPluginArrowhead'},
    component: () => import("@/views/Plugins/SharedImportPlugins/SharedImportPluginsView"),
  },
  {
    path: "/plugins/install/biopharm/:id?",
    name: "AddPluginBioPharm",
    meta: { protected: true, account_needed: true },
    props: routeProps,
    component: () => import("@/views/Plugins/AddSharedPlugin"),
  },
  {
    path: "/biopharm/import",
    name: "BioPharmImport",
    meta: { protected: true, account_needed: true },
    props: {component_name: 'AddPluginBioPharm'},
    component: () => import("@/views/Plugins/SharedImportPlugins/SharedImportPluginsView"),
  },
  {
    path: "/plugins/install/optimizerx/:id?",
    name: "AddPluginOptimizeRx",
    meta: { protected: true, account_needed: true },
    props: routeProps,
    component: () => import("@/views/Plugins/AddSharedPlugin"),
  },
  {
    path: "/optimizerx/import",
    name: "PluginOptimizeRxImport",
    meta: { protected: true, account_needed: true },
    props: {component_name: 'AddPluginOptimizeRx'},
    component: () => import("@/views/Plugins/SharedImportPlugins/SharedImportPluginsView"),
  },
  {
    path: "/plugins/install/pulsepoint_hcp365/:id?",
    name: "AddPluginPulsePointHCP365",
    meta: { protected: true, account_needed: true },
    props: routeProps,
    component: () => import("@/views/Plugins/AddSharedPlugin"),
  },
  {
    path: "/pulsepoint_hcp365/import",
    name: "PluginPulsePointHCP365Import",
    meta: { protected: true, account_needed: true },
    props: {component_name: 'AddPluginPulsePointHCP365'},
    component: () => import("@/views/Plugins/SharedImportPlugins/SharedImportPluginsView"),
  },
  {
    path: "/plugins/install/pulsepointlife/:id?",
    name: "AddPluginPulsePointLife",
    meta: { protected: true, account_needed: true },
    props: routeProps,
    component: () => import("@/views/Plugins/AddSharedPlugin"),
  },
  {
    path: "/pulsepointlife/import",
    name: "PluginPulsePointLifeImport",
    meta: { protected: true, account_needed: true },
    props: {component_name: 'AddPluginPulsePointLife'},
    component: () => import("@/views/Plugins/SharedImportPlugins/SharedImportPluginsView"),
  },
  {
    path: "/plugins/install/bulletin_healthcare/:id?",
    name: "AddPluginBulletinHealthcare",
    meta: { protected: true, account_needed: true },
    props: routeProps,
    component: () => import("@/views/Plugins/AddSharedPlugin"),
  },
  {
    path: "/bulletin_healthcare/import",
    name: "BulletinHealthcareImport",
    meta: { protected: true, account_needed: true },
    props: {component_name: 'AddPluginBulletinHealthcare'},
    component: () => import("@/views/Plugins/SharedImportPlugins/SharedImportPluginsView"),
  },
  {
    path: "/plugins/install/rxnt/:id?",
    name: "AddPluginRxNT",
    meta: { protected: true, account_needed: true },
    props: routeProps,
    component: () => import("@/views/Plugins/AddSharedPlugin"),
  },
  {
    path: "/plugins/install/mckesson/:id?",
    name: "AddPluginMcKesson",
    meta: { protected: true, account_needed: true },
    props: routeProps,
    component: () => import("@/views/Plugins/AddPluginMcKesson"),
  },
  {
    path: "/plugins/install/doximity/:id?",
    name: "AddPluginDoximity",
    meta: { protected: true, account_needed: true },
    props: routeProps,
    component: () => import("@/views/Plugins/AddSharedPlugin"),
  },
  {
    path: "/plugins/install/prescription_data/:id?",
    name: "AddPluginPrescriptionData",
    meta: { protected: true, account_needed: true },
    props: routeProps,
    component: () => import("@/views/Plugins/AddPluginPrescriptionData"),
  },
  {
    path: "/prescription_data/report",
    name: "PrescriptionReportView",
    meta: { protected: true, account_needed: true },
    props: routeProps,
    component: () => import("@/views/Plugins/PrescriptionData/PrescriptionReportView"),
  },
  {
    path: "/plugins/install/webmd/:id?",
    name: "AddPluginWebMD",
    meta: { protected: true, account_needed: true },
    props: routeProps,
    component: () => import("@/views/Plugins/WebMDSharedPlugin"),
  },
  {
    path: "/plugins/install/mdedge/:id?",
    name: "AddPluginMDedge",
    meta: { protected: true, account_needed: true },
    props: routeProps,
    component: () => import("@/views/Plugins/WebMDSharedPlugin"),
  },
  {
    path: "/plugins/install/medscape/:id?",
    name: "AddPluginMedscape",
    meta: { protected: true, account_needed: true },
    props: routeProps,
    component: () => import("@/views/Plugins/WebMDSharedPlugin"),
  },
  {
    path: "/plugins/install/veradigm/:id?",
    name: "AddPluginVeradigm",
    meta: { protected: true, account_needed: true },
    props: routeProps,
    component: () => import("@/views/Plugins/AddSharedPlugin"),
  },
  {
    path: "/plugins/install/notification/:id?",
    name: "AddPluginNotification",
    meta: { protected: true, account_needed: true },
    props: routeProps,
    component: () => import("@/views/Plugins/AddPluginNotification"),
  },
  {
    path: "/notification",
    name: "PluginNotificationView",
    meta: { protected: true, account_needed: true },
    component: () => import("@/views/Plugins/Notification/PluginNotificationView"),
  },
  {
    path: "/plugins/install/lahlouh/:id?",
    name: "AddPluginLahlouh",
    meta: { protected: true, account_needed: true },
    props: routeProps,
    component: () => import("@/views/Plugins/AddPluginLahlouh"),
  },
  {
    path: "/plugins/install/dcc/:id?",
    name: "AddPluginDCC",
    meta: { protected: true, account_needed: true },
    props: routeProps,
    component: () => import("@/views/Plugins/AddPluginDCC"),
  },
  {
    path: "/plugins/install/pvalue/:id?",
    name: "AddPluginPvalue",
    meta: { protected: true, account_needed: true },
    props: routeProps,
    component: () => import("@/views/Plugins/AddPluginPvalue"),
  },
  {
    path: "/plugins/install/sermo/:id?",
    name: "AddPluginSermo",
    meta: { protected: true, account_needed: true },
    props: routeProps,
    component: () => import("@/views/Plugins/AddSharedPlugin"),
  },
  {
    path: "/plugins/install/veeva/:id?",
    name: "AddPluginVeeva",
    meta: { protected: true, account_needed: true },
    props: routeProps,
    component: () => import("@/views/Plugins/AddPluginVeeva"),
  },
  {
    path: "/veeva/config/:accountId",
    name: "PluginVeevaConfig",
    meta: { protected: true, account_needed: false },
    props: route => ({ ...route.query, ...route.params, installed: true }),
    component: () => import("@/views/Plugins/AddPluginVeeva"),
  },
  {
    path: "/plugins/install/vumedi/:id?",
    name: "AddPluginVuMedi",
    meta: { protected: true, account_needed: true },
    props: routeProps,
    component: () => import("@/views/Plugins/AddSharedPlugin"),
  },
  {
    path: "/plugins/install/ehs/:id?",
    name: "AddPluginEHealthcareSolutions",
    meta: { protected: true, account_needed: true },
    props: routeProps,
    component: () => import("@/views/Plugins/AddSharedPlugin"),
  },
  {
    path: "/plugins/install/mdlinx/:id?",
    name: "AddPluginMDLinx",
    meta: { protected: true, account_needed: true },
    props: routeProps,
    component: () => import("@/views/Plugins/AddSharedPlugin"),
  },
  {
    path: "/plugins/install/medpage_today/:id?",
    name: "AddPluginMedPageToday",
    meta: { protected: true, account_needed: true },
    props: routeProps,
    component: () => import("@/views/Plugins/AddSharedPlugin"),
  },
  {
    path: "/plugins/install/mng_health/:id?",
    name: "AddPluginMngHealth",
    meta: { protected: true, account_needed: true },
    props: routeProps,
    component: () => import("@/views/Plugins/AddSharedPlugin"),
  },
  {
    path: "/plugins/install/reachmd/:id?",
    name: "AddPluginReachMD",
    meta: { protected: true, account_needed: true },
    props: routeProps,
    component: () => import("@/views/Plugins/AddSharedPlugin"),
  },
  {
    path: "/plugins/install/rep_management/:id?",
    name: "AddPluginRepManagement",
    meta: { protected: true, account_needed: true },
    props: routeProps,
    component: () => import("@/views/Plugins/AddPluginRepManagement"),
  },
  {
    path: "/rep_management/report",
    name: "RepManagementReportView",
    meta: { protected: true, account_needed: true },
    component: () => import("@/views/Plugins/RepManagement/RepManagementReportView"),
  },
  {
    path: "/plugins/install/typeform/:id?",
    name: "AddPluginTypeform",
    meta: { protected: true, account_needed: true },
    props: routeProps,
    component: () => import("@/views/Plugins/AddPluginTypeform"),
  },
  {
    path: "/plugins/install/epocrates/:id?",
    name: "AddPluginEpocrates",
    meta: { protected: true, account_needed: true },
    props: routeProps,
    component: () => import("@/views/Plugins/AddPluginEpocrates"),
  },
  {
    path: "/plugins/install/healthcasts/:id?",
    name: "AddPluginHealthcasts",
    meta: { protected: true, account_needed: true },
    props: route => ({ ...route.query, ...route.params, installed: (String(route.query.installed).toLowerCase() === 'true') }),
    component: () => import("@/views/Plugins/AddSharedPlugin"),
  },
  {
    path: "/plugins/install/haymarket/:id?",
    name: "AddPluginHaymarket",
    meta: { protected: true, account_needed: true },
    props: route => ({ ...route.query, ...route.params, installed: (String(route.query.installed).toLowerCase() === 'true') }),
    component: () => import("@/views/Plugins/AddSharedPlugin"),
  },
  {
    path: "/plugins/install/doceree/:id?",
    name: "AddPluginDoceree",
    meta: { protected: true, account_needed: true },
    props: route => ({ ...route.query, ...route.params, installed: (String(route.query.installed).toLowerCase() === 'true') }),
    component: () => import("@/views/Plugins/AddSharedPlugin"),
  },
  {
    path: "/plugins/install/phreesia/:id?",
    name: "AddPluginPhreesia",
    meta: { protected: true, account_needed: true },
    props: route => ({ ...route.query, ...route.params, installed: (String(route.query.installed).toLowerCase() === 'true') }),
    component: () => import("@/views/Plugins/AddPluginPhreesia"),
  },
  {
    path: "/rxnt/import",
    name: "RxNTImport",
    meta: { protected: true, account_needed: true },
    props: {component_name: 'AddPluginRxNT'},
    component: () => import("@/views/Plugins/SharedImportPlugins/SharedImportPluginsView"),
  },
  {
    path: "/mckesson/import",
    name: "PluginMcKessonImport",
    meta: { protected: true, account_needed: true },
    props: {component_name: 'AddPluginMcKesson'},
    component: () => import("@/views/Plugins/SharedImportPlugins/SharedImportPluginsView"),
  },
  {
    path: "/doximity/import",
    name: "PluginDoximityImport",
    meta: { protected: true, account_needed: true },
    props: {component_name: 'AddPluginDoximity'},
    component: () => import("@/views/Plugins/SharedImportPlugins/SharedImportPluginsView"),
  },
  {
    path: "/prescription_data/import",
    name: "PluginPrescriptionDataImport",
    meta: { protected: true, account_needed: true },
    props: {component_name: 'AddPluginPrescriptionData'},
    component: () => import("@/views/Plugins/SharedImportPlugins/SharedImportPluginsView"),
  },
  {
    path: "/webmd/import",
    name: "WebMDImport",
    meta: { protected: true, account_needed: true },
    props: {component_name: 'AddPluginWebMD'},
    component: () => import("@/views/Plugins/SharedImportPlugins/SharedImportPluginsView"),
  },
  {
    path: "/mdedge/import",
    name: "MDedgeImport",
    meta: { protected: true, account_needed: true },
    props: {component_name: 'AddPluginMDedge'},
    component: () => import("@/views/Plugins/SharedImportPlugins/SharedImportPluginsView"),
  },
  {
    path: "/medscape/import",
    name: "MedscapeImport",
    meta: { protected: true, account_needed: true },
    props: {component_name: 'AddPluginMedscape'},
    component: () => import("@/views/Plugins/SharedImportPlugins/SharedImportPluginsView"),
  },
  {
    path: "/veradigm/import",
    name: "VeradigmImport",
    meta: { protected: true, account_needed: true },
    props: {component_name: 'AddPluginVeradigm'},
    component: () => import("@/views/Plugins/SharedImportPlugins/SharedImportPluginsView"),
  },
  {
    path: "/lahlouh/import",
    name: "PluginLahlouhImport",
    meta: { protected: true, account_needed: true },
    component: () => import("@/views/Plugins/Lahlouh/LahlouhImportsView"),
  },
  {
    path: "/lahlouh/export",
    name: "PluginLahlouhExport",
    meta: { protected: true, account_needed: true },
    component: () => import("@/views/Plugins/Lahlouh/LahlouhExportsView"),
  },
  {
    path: "/dcc/import",
    name: "PluginDccImport",
    meta: { protected: true, account_needed: true },
    component: () => import("@/views/Plugins/Dcc/DccImportsView"),
  },
  {
    path: "/dcc/export",
    name: "PluginDccExport",
    meta: { protected: true, account_needed: true },
    component: () => import("@/views/Plugins/Dcc/DccExportsView"),
  },
  {
    path: "/pvalue/import",
    name: "PluginPvalueImport",
    meta: { protected: true, account_needed: true },
    props: {component_name: 'AddPluginPvalue'},
    component: () => import("@/views/Plugins/SharedImportPlugins/SharedImportPluginsView"),
  },
  {
    path: "/sermo/import",
    name: "SermoImport",
    meta: { protected: true, account_needed: true },
    props: {component_name: 'AddPluginSermo'},
    component: () => import("@/views/Plugins/SharedImportPlugins/SharedImportPluginsView"),
  },
  {
    path: "/vumedi/import",
    name: "PluginVuMediImport",
    meta: { protected: true, account_needed: true },
    props: {component_name: 'AddPluginVuMedi'},
    component: () => import("@/views/Plugins/SharedImportPlugins/SharedImportPluginsView"),
  },
  {
    path: "/ehs/import",
    name: "EHealthcareSolutionsImport",
    meta: { protected: true, account_needed: true },
    props: { component_name: "AddPluginEHealthcareSolutions" },
    component: () => import("@/views/Plugins/SharedImportPlugins/SharedImportPluginsView"),
  },
  {
    path: "/rep_management/import",
    name: "PluginRepManagementImport",
    meta: { protected: true, account_needed: true },
    props: {component_name: 'AddPluginRepManagement'},
    component: () => import("@/views/Plugins/SharedImportPlugins/SharedImportPluginsView"),
  },
  {
    path: "/rep_management/export",
    name: "PluginRepManagementExport",
    meta: { protected: true, account_needed: true },
    component: () => import("@/views/Plugins/RepManagement/RepManagementExportsView"),
  },
  {
    path: "/mng_health/import",
    name: "PluginMngHealthImport",
    meta: { protected: true, account_needed: true },
    props: {component_name: 'AddPluginMngHealth'},
    component: () => import("@/views/Plugins/SharedImportPlugins/SharedImportPluginsView"),
  },
  {
    path: "/reachmd/import",
    name: "PluginReachMDImport",
    meta: { protected: true, account_needed: true },
    props: {component_name: 'AddPluginReachMD'},
    component: () => import("@/views/Plugins/SharedImportPlugins/SharedImportPluginsView"),
  },
  {
    path: "/mdlinx/import",
    name: "PluginMDLinxImport",
    meta: { protected: true, account_needed: true },
    props: {component_name: 'AddPluginMDLinx'},
    component: () => import("@/views/Plugins/SharedImportPlugins/SharedImportPluginsView"),
  },
  {
    path: "/medpage_today/import",
    name: "PluginMedPageTodayImport",
    meta: { protected: true, account_needed: true },
    props: {component_name: 'AddPluginMedPageToday'},
    component: () => import("@/views/Plugins/SharedImportPlugins/SharedImportPluginsView"),
  },
  {
    path: "/doceree/import",
    name: "DocereeImport",
    meta: { protected: true, account_needed: true },
    props: {component_name: 'AddPluginDoceree'},
    component: () => import("@/views/Plugins/SharedImportPlugins/SharedImportPluginsView"),
  },
  {
    path: "/epocrates/import",
    name: "PluginEpocratesImport",
    meta: { protected: true, account_needed: true },
    props: {component_name: 'AddPluginEpocrates'},
    component: () => import("@/views/Plugins/SharedImportPlugins/SharedImportPluginsView"),
  },
  {
    path: "/healthcasts/import",
    name: "HealthcastsImport",
    meta: { protected: true, account_needed: true },
    props: {component_name: 'AddPluginHealthcasts'},
    component: () => import("@/views/Plugins/SharedImportPlugins/SharedImportPluginsView"),
  },
  {
    path: "/haymarket/import",
    name: "HaymarketImport",
    meta: { protected: true, account_needed: true },
    props: {component_name: 'AddPluginHaymarket'},
    component: () => import("@/views/Plugins/SharedImportPlugins/SharedImportPluginsView"),
  },
];